import React from 'react';
import Dropzone from 'react-dropzone'
import axios from 'axios';
import './App.css';
import logo from './logo.png'


// const API = "http://localhost:5000"
const API = "https://trichome.flyovertechnologies.com"
const ENDPOINT = API + "/predict"

const renderProbability = prob => {
  return (prob*100).toFixed(2)
}

class App extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      img: null,
      probabilities: {},
      dragging: false,
    }

    this.handleDrop = this.handleDrop.bind(this);
  }

  classify(file) {
    this.setState({ classifying: true }, () => {
      const formData = new FormData();
      formData.append("image", file)

      axios.post(ENDPOINT,  formData)
        .then(res => {
          let { prediction, probabilities } = res.data;

          // don't display bud probabilities if prediction is low
          const bud_prediction = probabilities['bud'];
          delete probabilities["bud"]
          if (prediction === "bud") {
            // Rename to more user friendly "dried-bud"
            probabilities["dried-bud"] = bud_prediction
            prediction = "dried-bud"
          }
          this.setState({ prediction, probabilities, classifying: false })
        })
        .catch(err => {
          this.setState({ prediction: "bad picture", classifying: false })
        })
    })
  }

  handleDrop(files) {
    var img = files[0]
    const reader = new FileReader();
    reader.onload = (event) => {
      this.setState({ img:  event.target.result }) // blob url
      this.classify(img)
    };
    reader.readAsDataURL(img)
  }

  renderInstructions(isDragActive) {
    if (this.state.classifying) {
      return (<p>Classifying...</p>)
    }

    if (isDragActive) {
      return (<p>Detected new file. Release when ready.</p>)
    }

    if (this.state.prediction) {
      return (<p>"{this.state.prediction}"</p>)
    } else {
      return (<p>Drag an image from your computer onto the screen to classify.</p>)
    }
  }


  render() {
    const probs = this.state.probabilities;
    const shouldHideTable = Object.keys(probs).length === 0;

    // sorted predictions descending
    const keys = Object.keys(probs).sort((a,b) => (probs[b]-probs[a]));

    return (
      <div className="App">
        <header className="App-header">
          <a href="/" style={{textDecoration: "none", color: "inherit"}}><h1>Trichome AI</h1></a>
          <Dropzone onDrop={this.handleDrop} multiple={false}>
            {({getRootProps, getInputProps, isDragActive}) => (
              <section>
                { this.renderInstructions(isDragActive) }
                <div {...getRootProps()} id="drop" className={isDragActive ? "dragged" : ""}>
                  <input {...getInputProps()} />
                  {
                    this.state.img ? (
                      <img style={{maxWidth: "100%", maxHeight: "100%"}} src={this.state.img} alt=""/>
                    ) : (
                      <code style={{ alignSelf: "center" }}>Click me</code>
                    )
                  }
                </div>
              </section>
            )}
          </Dropzone>

            <table style={{ minHeight: "20vh", visibility: shouldHideTable ? "hidden" : "inherit" }}>
              <thead>
                <tr>
                  <th className="left">Prediction</th>
                  <th className="right">Confidence</th>
                </tr>
              </thead>
              <tbody>
                {
                  keys.map((k,i) => (
                    <tr key={i}>
                      <td className="left">{k}</td>
                      <td className="right"><pre>{renderProbability(this.state.probabilities[k])}%</pre></td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            <div className="footer">
              AI powered by <a href="https://flyovertechnologies.com"><img className="logo" src={logo} alt="logo"/></a>
            </div>
        </header>
      </div>
    );
  }
}

export default App;
